/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import { Avatar, Col, Title } from '../Ant';
import { Mention } from './index';

const Feed = () => {
  const dispatch = useDispatch();

  const checkTags = index => {
    let auxValue = false;
    feed[index].tags.forEach(e => {
      if (e.slug === 'interactive') {
        auxValue = true;
      }
    });
    return auxValue;
  };
  const { feed } = useSelector(Store.posts.selectors.get);

  const getImageURL = source => {
    let auxString = '';

    if (source.includes('images.unsplash')) {
      auxString = source.replace('w=1080', 'w=400');
    } else {
      auxString = source.replace('images', 'images/size/w600');
    }
    return auxString;
  };

  const getAvatarURL = source => {
    let auxString = '';
    auxString = source.replace('images', 'images/size/w100');
    return auxString;
  };

  useEffect(() => {
    if (!feed) {
      dispatch(Store.posts.actions.getPosts({ limit: 9 }));
    }
  }, []);

  const renderColumn = (e, i) => {
    return (
      <Col key={`feed-${i}`} xs={24} sm={12} md={8}>
        <Mention>
          <a href={e.url}>
            {e && checkTags(i) && (
              <div className="interactive">
                <FaMapMarkerAlt />
                Interactive
              </div>
            )}
            <img className="lazyload cover" alt={e.title} data-src={getImageURL(e.feature_image)} />
            <div className="body">
              <Title level={4}>{e.title}</Title>
              <div className="reading-time">{e.reading_time} MIN READ</div>
              <div className="author">
                {e.authors.map((ele, index) => (
                  <Avatar
                    key={`art-avatar-${index}`}
                    src={getAvatarURL(ele.profile_image)}
                    alt={e.primary_author.name}
                  />
                ))}
              </div>
            </div>
          </a>
        </Mention>
        {/* <span>{e.excerpt}</span> */}
      </Col>
    );
  };
  const renderSkeleton = (e, i) => {
    return (
      <Col key={`feed-${i}`} xs={24} sm={12} md={8}>
        <Mention>
          <Skeleton.Button active={true} style={{ width: '100%', height: 200 }} size="small" />
          <div className="body">
            <Skeleton active={true} size="default" paragraph={{ rows: 1, width: 300 }} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '1rem',
              }}
            >
              <Skeleton.Avatar active={true} size="large" />
              <Skeleton.Button
                active={true}
                style={{ width: 132, height: 16 }}
                size="small"
                shape="square"
              />
            </div>
          </div>
        </Mention>
        {/* <span>{e.excerpt}</span> */}
      </Col>
    );
  };

  return (
    <>
      {feed
        ? feed.map((feed, i) => renderColumn(feed, i))
        : [0, 1, 2, 3, 4, 5, 6, 7, 8].map((feed, i) => renderSkeleton(feed, i))}
    </>
  );
};

export default Feed;
